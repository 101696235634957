import React, { useState } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import styled from "styled-components";
import { toSlug } from "../components/helpers";
import { navigate, graphql } from "gatsby";
import {
  darkPurple,
  innerWidth,
  mainPurple,
  mainWhite,
  screen,
  subFont,
  moveBgColor,
} from "../components/variables";
import Button from "../components/button";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import CTA from "../components/cta";
import CarouselTestimonials from "../components/carousel/testimonials";
import LogoTableau from "../images/svg/Tableau-Logo-RGB.svg";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import GeneralForm from "../components/form/general-form";
import PackagesForm from "../components/form/packages-form";

const Wrapper = styled.div`
  padding: 60px 0 0 0;
  @media ${screen.xsmall} {
    padding: 100px 0 0 0;
  }

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 25px;
    @media ${screen.xsmall} {
      padding: 0 40px;
    }
    @media ${screen.medium} {
      padding: 0;
    }
  }

  .hero {
    overflow: hidden;
    position: relative;
    @media ${screen.xsmall} {
      background-image: ${(props) => `url(${props.banner_background_image})`};
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      display: flex;
      align-items: center;
      min-height: 610px;
    }

    .gradient-bg {
      position: absolute;
      background-image: linear-gradient(120deg, #67b6ff, #3697ed, #6300ff);
      background-size: 250%;
      animation: ${moveBgColor} 5s infinite alternate;
      width: 100%;
      height: 100%;
      @media ${screen.xsmall} {
        position: absolute;
        width: 80%;
        height: 180%;
        left: -22%;
        top: 0;
        border-radius: 50%;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .text-wrapper {
      padding: 50px 25px 70px 25px;
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        width: 60%;
        padding: 0 80px 0 0;
      }

      .text-subwrapper {
        max-width: 600px;
        margin: 0 0 0 auto;
        @media ${screen.xsmall} {
          padding: 0 50px 0 40px;
        }
        @media ${screen.medium} {
          padding: 0 50px 0 0;
        }

        .description {
          margin: 22px 0 32px 0;
          position: relative;
          z-index: 2;
          color: ${mainWhite};

          h1 {
            font-family: ${subFont};
            font-size: 2.38rem;
            color: ${darkPurple};
            line-height: 1.05;
            margin: 0 0 6px 0;
            @media ${screen.xsmall} {
              font-size: 3.7rem;
            }
            @media ${screen.small} {
              font-size: 3.9rem;
            }
            @media ${screen.medium} {
              font-size: 4.15rem;
            }

            strong {
              color: ${mainWhite};
              display: block;
              margin: 0 0 0 52px;
            }
          }

          p {
            font-size: 1.05rem;
            line-height: 1.4;
            margin: 0 0 21px 0;
            @media ${screen.xsmall} {
              font-size: 1.3rem;
            }
            @media ${screen.small} {
              font-size: 1.4rem;
            }
            @media ${screen.medium} {
              font-size: 1.5rem;
            }
            &:last-child {
              margin: 0;
            }
          }
        }

        .btn {
          position: relative;
          z-index: 2;

          button {
            @media ${screen.withCursor} {
              &:hover {
                background: ${darkPurple};
                color: ${mainWhite};
              }
            }
          }
        }
      }
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: calc(50% + 60px);
      transform: translateY(-50%);
      left: -120px;
      pointer-events: none;
      width: 500px;
      @media ${screen.xsmall} {
        width: 950px;
        left: -400px;
        top: 50%;
      }
      @media ${screen.small} {
        width: 900px;
        left: -250px;
      }
      @media ${screen.medium} {
        width: 1000px;
      }
      @media ${screen.large} {
        width: calc(750px + 12vw);
        left: -70px;
      }
    }
  }

  .intro {
    padding: 55px 0 120px 0;
    @media ${screen.xsmall} {
      padding: 80px 0 125px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0 135px 0;
    }

    .description {
      max-width: 540px;

      h2 {
        font-family: ${subFont};
        font-size: 1.55rem;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.8rem;
        }
        @media ${screen.small} {
          font-size: 1.9rem;
        }
        @media ${screen.medium} {
          font-size: 2rem;
        }

        strong {
          color: ${mainPurple};
        }
      }

      p {
        font-size: 1.05rem;
        margin: 0 0 21px 0;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .flex {
      margin: 40px 0 0 0;
      @media ${screen.xsmall} {
        display: flex;
      }

      .services {
        @media ${screen.xsmall} {
          max-width: 400px;
          width: 100%;
        }

        .heading {
          font-size: 1.35rem;
          font-weight: 700;
          margin: 0 0 25px 0;
        }

        .service-list {
          &__each {
            a {
              color: ${darkPurple};
              cursor: pointer;
              border: 1.5px ${mainPurple} solid;
              padding: 13px;
              display: block;
              text-align: center;
              margin: 0 0 22px 0;
              border-radius: 14px;
              line-height: 1.35;
              width: 100%;
              @media ${screen.xsmall} {
                padding: 15px;
                margin: 0 0 20px 0;
              }
              @media ${screen.small} {
                padding: 17px;
              }
              @media ${screen.medium} {
                padding: 19px;
              }
              @media ${screen.withCursor} {
                &:hover {
                  border: 1.5px #6320ff solid;
                  background-image: linear-gradient(to right, #67b6ff, #6300ff);
                  color: ${mainWhite};
                }
              }

              .text {
                p {
                  font-size: 1.05rem;
                  display: inline-block;
                  font-weight: 700;
                  @media ${screen.xsmall} {
                    font-size: 1.15rem;
                  }
                  @media ${screen.small} {
                    font-size: 1.2rem;
                  }
                  @media ${screen.medium} {
                    font-size: 1.25rem;
                  }

                  em {
                    display: block;
                    font-style: normal;
                    font-size: 1.02rem;
                    @media ${screen.xsmall} {
                      font-size: 0.92rem;
                    }
                    @media ${screen.small} {
                      font-size: 0.97rem;
                    }
                    @media ${screen.medium} {
                      font-size: 1.02rem;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .slides {
        margin: 50px 0 0 0;
        @media ${screen.xsmall} {
          padding: 0 0 0 50px;
          width: calc(100% - 300px);
          margin: 0;
        }
        @media ${screen.small} {
          padding: 0 0 0 70px;
          width: calc(100% - 360px);
        }

        .slick-dots {
          bottom: -50px;

          li {
            margin: 0 2px;

            button {
              border-radius: 50%;
              border: 1px ${darkPurple} solid;
              width: 13px;
              height: 13px;

              &::before {
                opacity: 0;
              }
            }
          }

          .slick-active {
            button {
              background: ${darkPurple};
            }
          }
        }
      }
    }
  }

  .tableau {
    padding: 55px 0;
    background-image: ${(props) => `url(${props.tableau_background_image})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    @media ${screen.xsmall} {
      padding: 100px 0;
    }
    @media ${screen.small} {
      padding: 110px 0;
    }

    .box {
      background: rgba(255, 255, 255, 0.8);
      max-width: 500px;
      padding: 30px 30px 35px 30px;
      border-radius: 15px;
      position: relative;
      z-index: 2;
      @media ${screen.xsmall} {
        padding: 40px;
        border-radius: 24px;
      }
      @media ${screen.small} {
        padding: 50px;
        border-radius: 28px;
      }

      .heading {
        font-family: ${subFont};
        font-size: 1.55rem;
        color: ${darkPurple};
        line-height: 1.16;
        margin: 0 0 10px 0;
        @media ${screen.xsmall} {
          font-size: 2.52rem;
        }
        @media ${screen.small} {
          font-size: 2.62rem;
        }
        @media ${screen.medium} {
          font-size: 2.72rem;
        }
        span {
          color: ${mainPurple};
        }
        .logo {
          display: block;
          max-width: 210px;
          margin: 10px 0 0 auto;
          @media ${screen.xsmall} {
            max-width: 270px;
          }
          @media ${screen.small} {
            max-width: 290px;
          }
          @media ${screen.medium} {
            max-width: 300px;
          }

          svg {
          }
        }
      }
      .description {
        margin: 0 0 20px 0;

        h2 {
          font-family: ${subFont};
          font-size: 3rem;
          color: ${darkPurple};
          line-height: 1.05;
          margin: 0 0 20px 0;

          i {
            color: ${mainPurple};
            display: block;
            margin: 0 0 0 70px;
          }
        }

        p {
          font-size: 1.05rem;
          margin: 0 0 21px 0;
          font-weight: 500;
          @media ${screen.xsmall} {
            font-size: 1.15rem;
          }
          @media ${screen.small} {
            font-size: 1.2rem;
          }
          @media ${screen.medium} {
            font-size: 1.25rem;
          }
          &:last-child {
            margin: 0;
          }
        }
      }

      .btn {
        button {
          background: ${darkPurple};
          color: ${mainWhite};
          @media ${screen.withCursor} {
            &:hover {
              background: ${mainPurple};
            }
          }
        }
      }
    }
  }

  .offer {
    background-image: linear-gradient(-90deg, #67b6ff, #3697ed, #6300ff);
    background-size: 400%;
    animation: ${moveBgColor} 5s infinite alternate;
    padding: 55px 0 30px 0;
    position: relative;
    overflow: hidden;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .arrows-bg {
      position: absolute;
      z-index: 2;
      top: 100px;
      left: -150px;
      pointer-events: none;
      width: 450px;
      @media ${screen.xsmall} {
        width: 900px;
        left: -400px;
        top: 50%;
        top: calc(50% + 60px);
        transform: translateY(-50%);
      }
      @media ${screen.small} {
        width: 850px;
        left: -250px;
      }
      @media ${screen.medium} {
        width: 950px;
      }
      @media ${screen.large} {
        width: calc(700px + 12vw);
        left: -70px;
      }
    }

    .flex {
      position: relative;
      z-index: 3;
      @media ${screen.xsmall} {
        display: flex;
        align-items: center;
      }

      .col {
        &--left {
          @media ${screen.xsmall} {
            padding: 0 60px 0 0;
            width: 45%;
          }
          @media ${screen.medium} {
            width: 35%;
          }

          .description {
            max-width: 500px;
            margin: 0 0 32px 0;

            h2 {
              font-family: ${subFont};
              font-size: 1.55rem;
              color: ${darkPurple};
              line-height: 1.16;
              margin: 0 0 20px 0;
              @media ${screen.xsmall} {
                font-size: 2.52rem;
              }
              @media ${screen.small} {
                font-size: 2.62rem;
              }
              @media ${screen.medium} {
                font-size: 2.72rem;
              }

              strong {
                color: ${mainWhite};
                @media ${screen.xsmall} {
                  display: block;
                }
              }
            }

            p {
              color: ${mainWhite};
              font-size: 1.05rem;
              margin: 0 0 21px 0;
              @media ${screen.xsmall} {
                font-size: 1.15rem;
              }
              @media ${screen.small} {
                font-size: 1.2rem;
              }
              @media ${screen.medium} {
                font-size: 1.25rem;
              }
              &:last-child {
                margin: 0;
              }
            }
          }

          .btn {
            button {
              @media ${screen.withCursor} {
                &:hover {
                  background: ${darkPurple};
                  color: ${mainWhite};
                }
              }
            }
          }
        }

        &--right {
          margin: 50px 0 0 0;
          @media ${screen.xsmall} {
            width: 55%;
            margin: 0;
          }
          @media ${screen.medium} {
            width: 65%;
          }

          .item-list {
            display: flex;
            flex-wrap: wrap;
            margin: 0 -15px;

            &__each {
              width: calc(50% - 30px);
              text-align: center;
              margin: 0 15px 36px 15px;
              @media ${screen.xsmall} {
                width: calc(50% - 30px);
                margin: 0 15px 30px 15px;
              }
              @media ${screen.small} {
                width: calc(25% - 30px);
                margin: 0 15px;
              }

              .img {
                display: block;
                width: 82px;
                margin: 0 auto;
                @media ${screen.xsmall} {
                  width: 100px;
                }
              }

              .name {
                font-size: 0.95rem;
                font-weight: 700;
                color: ${mainWhite};
                margin: 20px 0 0 0;
                line-height: 1.35;
                @media ${screen.small} {
                  font-size: 1.1rem;
                }
                @media ${screen.medium} {
                  font-size: 1.25rem;
                }
              }
            }
          }
        }
      }
    }
  }

  .clients {
    padding: 55px 0 35px 0;
    @media ${screen.xsmall} {
      padding: 80px 0;
    }
    @media ${screen.medium} {
      padding: 90px 0;
    }

    .description {
      text-align: center;
      max-width: 540px;
      margin: 0 auto;

      h2 {
        font-family: ${subFont};
        font-size: 1.55rem;
        margin: 0 0 20px 0;
        @media ${screen.xsmall} {
          font-size: 1.8rem;
        }
        @media ${screen.small} {
          font-size: 1.9rem;
        }
        @media ${screen.medium} {
          font-size: 2rem;
        }

        strong {
          color: ${mainPurple};
        }
      }

      p {
        font-size: 1.05rem;
        margin: 0 0 21px 0;
        @media ${screen.xsmall} {
          font-size: 1.15rem;
        }
        @media ${screen.small} {
          font-size: 1.2rem;
        }
        @media ${screen.medium} {
          font-size: 1.25rem;
        }

        &:last-child {
          margin: 0;
        }
      }
    }

    .client-list {
      display: flex;
      flex-wrap: wrap;
      margin: 30px -12px 0 -12px;
      @media ${screen.xsmall} {
        margin: 30px -20px 0 -20px;
      }

      &__each {
        width: calc(50% - 24px);
        margin: 0 12px 12px 12px;
        @media ${screen.xsmall} {
          width: calc(33.33% - 40px);
          margin: 0 20px 20px 20px;
          padding: 0 10px;
        }
        @media ${screen.small} {
          padding: 0 15px;
          width: calc(25% - 40px);
        }
      }
    }
  }
`;

const IndexPage = ({ data }) => {
  const [isGeneralForm, setIsGeneralForm] = useState(false);
  const [isPackagesForm, setIsPackagesForm] = useState(false);

  const {
    banner_background_image,
    banner_description,
    client_logo_list,
    clients_description,
    cta_background_image,
    cta_description,
    intro_description,
    intro_link_list,
    intro_slides,
    item_list,
    offer_description,
    tableau_background_image,
    tableau_description,
    title_tag,
    meta_description,
  } = data.main.data;

  return (
    <Layout>
      <Seo title={title_tag} description={meta_description} isHomePage={true} />
      <Wrapper
        banner_background_image={banner_background_image.url}
        tableau_background_image={tableau_background_image.url}
      >
        <header className="hero">
          <div className="gradient-bg" role="presentation"></div>
          <div className="text-wrapper">
            <div
              className="text-subwrapper"
              data-sal="slide-right"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: banner_description.html }}
              />
              <Button
                className="btn"
                onClick={() => {
                  setIsGeneralForm(true);
                }}
              >
                GET IN TOUCH
              </Button>
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/arrows_home-about-banner-arrows.png"
              alt="Graphics"
              placeholder="blurred"
            />
          </div>
        </header>

        <section className="intro">
          <div className="inner-wrapper">
            <div
              data-sal="slide-up"
              data-sal-duration="500"
              className="description"
              dangerouslySetInnerHTML={{ __html: intro_description.html }}
            />

            <div className="flex">
              <div
                className="services"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <h3 className="heading">Our Services</h3>

                <ul className="service-list">
                  {intro_link_list.map((item, i) => (
                    <li className="service-list__each" key={i}>
                      <AnchorLink to={`/services/#${toSlug(item.label.text)}`}>
                        <div
                          className="text"
                          dangerouslySetInnerHTML={{ __html: item.label.html }}
                        />
                      </AnchorLink>
                    </li>
                  ))}
                </ul>
              </div>
              <div
                className="slides"
                data-sal="slide-up"
                data-sal-duration="500"
              >
                <CarouselTestimonials items={intro_slides} />
              </div>
            </div>
          </div>
        </section>

        <section className="tableau">
          <div className="inner-wrapper">
            <div className="box" data-sal="slide-up" data-sal-duration="500">
              <h2 className="heading">
                ClearR <span>+</span>
                <span className="logo">
                  <LogoTableau />
                </span>
              </h2>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: tableau_description.html }}
              />
              <Button
                className="btn"
                onClick={() => {
                  navigate(`/tableau/`);
                }}
              >
                CLEARR’S TABLEAU SOLUTIONS
              </Button>
            </div>
          </div>
        </section>

        <section className="offer">
          <div className="inner-wrapper flex">
            <div
              className="col col--left"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: offer_description.html }}
              />
              <Button
                className="btn"
                onClick={() => {
                  setIsPackagesForm(true);
                }}
              >
                GET STARTED
              </Button>
            </div>
            <div
              className="col col--right"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              <ul className="item-list">
                {item_list.map((item) => (
                  <li className="item-list__each" key={item.name}>
                    <img
                      className="img"
                      src={item.image.url}
                      alt={item.image.alt || "Icon"}
                    />
                    <h3 className="name">{item.name}</h3>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          <div className="arrows-bg" role="presentation">
            <StaticImage
              src="../images/arrows_home-getstarted-arrows.png"
              alt="Graphic"
              placeholder="blurred"
            />
          </div>
        </section>

        <section className="clients">
          <div className="inner-wrapper">
            <div
              className="description"
              data-sal="slide-up"
              data-sal-duration="500"
              dangerouslySetInnerHTML={{ __html: clients_description.html }}
            />
            <ul
              className="client-list"
              data-sal="slide-up"
              data-sal-duration="500"
            >
              {client_logo_list.map((item, i) => (
                <li className="client-list__each" key={i}>
                  {item.website_url ? (
                    <div>
                      <a
                        href={item.website_url}
                        rel="noopener"
                        aria-label="Visit company website"
                        target="_blank"
                      >
                        <GatsbyImage
                          image={item.logo.thumbnails.cropped.gatsbyImageData}
                          alt={item.logo.alt || "Brand"}
                        />
                      </a>
                    </div>
                  ) : (
                    <div>
                      <GatsbyImage
                        image={item.logo.thumbnails.cropped.gatsbyImageData}
                        alt={item.logo.alt || "Brand"}
                      />
                    </div>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </section>

        <CTA
          bgImg={cta_background_image.url}
          className="cta"
          html={cta_description.html}
          setIsGeneralForm={setIsGeneralForm}
        />
      </Wrapper>
      <GeneralForm
        isGeneralForm={isGeneralForm}
        setIsGeneralForm={setIsGeneralForm}
      />
      <PackagesForm
        isPackagesForm={isPackagesForm}
        setIsPackagesForm={setIsPackagesForm}
      />
    </Layout>
  );
};

export default IndexPage;

export const dataQuery = graphql`
  {
    main: prismicHomePage {
      data {
        banner_background_image {
          url
        }
        banner_description {
          html
        }
        client_logo_list {
          website_url
          logo {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
        }
        clients_description {
          html
        }
        cta_background_image {
          url
        }
        cta_description {
          html
        }
        intro_description {
          html
        }
        intro_link_list {
          label {
            html
            text
          }
        }
        intro_slides {
          description {
            html
          }
          featured_image {
            alt
            thumbnails {
              cropped {
                gatsbyImageData(layout: FULL_WIDTH)
              }
            }
          }
          logo {
            alt
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
        item_list {
          name
          image {
            alt
            url
          }
        }
        offer_description {
          html
        }
        tableau_background_image {
          url
        }
        tableau_description {
          html
        }
        title_tag
        meta_description
      }
    }
  }
`;
