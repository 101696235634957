import React, { useState } from "react";
import styled from "styled-components";
import emailjs from "emailjs-com";
import {
  subFont,
  mainWhite,
  transHover,
  mainPurple,
  screen,
  darkPurple,
} from "../variables";
import { navigate } from "gatsby";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { motion, AnimatePresence } from "framer-motion";
import { ScrollLocky } from "react-scroll-locky";
import Button from "../button";
import { MdKeyboardArrowDown } from "react-icons/md";

const container = {
  hidden: { opacity: 0 },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.1,
    },
  },
};

const item = {
  hidden: { opacity: 0, x: -15 },
  show: { opacity: 1, x: 0 },
};

const Wrapper = styled(motion.div)`
  background: rgba(46, 41, 78, 0.98);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 21;

  .container {
    background: ${mainWhite};
    border-radius: 16px;
    max-width: 870px;
    width: calc(100% - 50px);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 50px 25px 50px 25px;
    max-height: calc(100vh - 100px);
    overflow-y: auto;
    @media ${screen.xsmall} {
      padding: 50px;
      border-radius: 20px;
      max-height: none;
    }

    @media ${screen.small} {
      border-radius: 25px;
    }

    @media ${screen.medium} {
      border-radius: 30px;
    }

    .description {
      max-width: 500px;
      margin: 0 0 35px 0;

      h3 {
        font-family: ${subFont};
        font-size: 1.25rem;
        margin: 0 0 6px 0;
        @media ${screen.xsmall} {
          font-size: 1.6rem;
          margin: 0 0 12px 0;
        }
        @media ${screen.small} {
          font-size: 1.7rem;
        }
        @media ${screen.medium} {
          font-size: 1.8rem;
        }

        strong {
          color: ${mainPurple};
          font-style: normal;
        }
      }

      p {
        font-size: 0.95rem;
        line-height: 1.4;
        @media ${screen.xsmall} {
          font-size: 1.2rem;
        }
      }
    }

    .form {
      width: 100%;
      @media ${screen.xsmall} {
        display: flex;
      }

      .col {
        .each-field {
          margin: 0 0 12px 0;
          @media ${screen.xsmall} {
            margin: 0 0 20px 0;
          }

          .label {
            display: block;
            margin: 0 0 5px 0;
            line-height: 1.35;
            font-size: 0.9rem;
            @media ${screen.xsmall} {
              font-size: 1rem;
              margin: 0 0 8px 0;
            }
          }

          .input {
            border: 0;
            display: block;
            width: 100%;
            border: 0;
            border-radius: 8px;
            background: #ece0ff;
            font-size: 16px;
            outline: none;
            padding: 11px 11px;
            @media ${screen.xsmall} {
            }
          }

          select {
            -webkit-appearance: none;
            -moz-appearance: none;
            -webkit-border-radius: 0px;
            text-indent: 1px;
            text-overflow: "";
            width: 100%;
            padding: 12px 14px;
            outline: none;
            background: #ece0ff;
            border: 0;
            border-radius: 8px;
            letter-spacing: 1px;
            font-size: 14px;
            cursor: pointer;
          }

          textarea {
            border: 0;
            display: block;
            width: 100%;
            border: 0;
            border-radius: 8px;
            background: #ece0ff;
            font-size: 16px;
            outline: none;
            padding: 11px 11px;
            resize: none;
            height: 88px;
          }

          &--dropdown {
            position: relative;

            svg {
              font-size: 1.5rem;
              position: absolute;
              right: 8px;
              bottom: 7px;
              color: ${darkPurple};
              pointer-events: none;
            }
          }

          &--checkboxes {
            .subwrapper {
              input {
                vertical-align: middle;
              }

              label {
                display: inline-block;
                padding-right: 10px;
                white-space: nowrap;
                margin: 0 0 12px 0;
                font-size: 0.9rem;
                @media ${screen.xsmall} {
                  font-size: 1rem;
                }
              }

              span {
                vertical-align: middle;
              }
            }
          }
        }

        .btn {
          margin: 20px 0 0 0;
          @media ${screen.xsmall} {
            margin: 20px 0 0 0;
          }

          button {
            background: ${darkPurple};
            color: ${mainWhite};
            @media ${screen.withCursor} {
              &:hover {
                background: ${mainPurple};
              }
            }
          }
        }

        &--left {
          @media ${screen.xsmall} {
            width: 50%;
            padding: 0 20px 0 0;
          }

          .img {
            display: none;
            @media ${screen.xsmall} {
              display: block;
              margin: 26px 0 0 85px;
              max-width: 150px;
            }
          }
        }

        &--right {
          @media ${screen.xsmall} {
            width: 50%;
            padding: 0 0 0 20px;
          }
        }
      }
    }

    .close-btn {
      font-size: 1.96rem;
      position: absolute;
      right: 12px;
      top: 10px;
      cursor: pointer;
      transition: ${transHover};
      @media ${screen.xsmall} {
        font-size: 2.16rem;
        right: 25px;
        top: 25px;
      }
      @media ${screen.withCursor} {
        &:hover {
          color: ${mainPurple};
        }
      }
    }
  }
`;

const PackagesForm = ({ isPackagesForm, setIsPackagesForm }) => {
  const isActive = isPackagesForm;

  const [userInput, setUserInput] = useState({});
  const [isSending, setIsSending] = useState(false);

  const handleChange = (e) => {
    userInput[e.target.name] = e.target.value;

    setUserInput({ ...userInput });
  };

  const handleSubmit = (e) => {
    // make array of selected checkboxes to string
    const interests = userInput.interests && userInput.interests.toString();

    e.preventDefault();
    setIsSending(true);

    emailjs
      .send(
        "service_domfn04",
        "template_fpswjee",
        { ...userInput, interests },
        "user_6UcVTbn8hTvSYTC5xgwbL"
      )
      .then(() => {
        setIsSending(false);
        setUserInput({});
        navigate("/success-submission/");
      })
      .catch(() => {
        setIsSending(false);
        alert("Sorry, Something went wrong. Please contact us immediately.");
      });
  };

  const handleCheckbox = (e) => {
    if (userInput.interests) {
      if (userInput.interests.indexOf(e.target.value) === -1) {
        userInput.interests = [...userInput.interests, e.target.value];
        setUserInput({ ...userInput });
      } else {
        const filtered = userInput.interests.filter(
          (item) => item !== e.target.value
        );
        userInput.interests = filtered;
        setUserInput({ ...userInput });
      }
    } else {
      userInput.interests = [e.target.value];
      setUserInput({ ...userInput });
    }
  };

  return (
    <AnimatePresence>
      {isActive && (
        <Wrapper
          id="member-lightbox"
          initial="hidden"
          animate={isActive ? "show" : "hidden"}
          variants={container}
          exit={{ opacity: 0 }}
        >
          <ScrollLocky isolation={false}>
            <div className="container">
              <motion.div variants={item} className="description">
                <h3>
                  Let’s <strong>get started</strong> now!
                </h3>
                <p>
                  Thanks for your interest in ClearR’s Quickstart packages. Let
                  us know which package(s) you’re interested in and we’ll be in
                  touch soon.
                </p>
              </motion.div>
              <motion.form
                variants={item}
                className="form"
                onSubmit={handleSubmit}
              >
                <div className="col col--left">
                  <div className="each-field">
                    <label className="label">First Name</label>
                    <input
                      className="input"
                      type="text"
                      name="first_name"
                      value={userInput.first_name || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field">
                    <label className="label">Last Name</label>
                    <input
                      className="input"
                      type="text"
                      name="last_name"
                      value={userInput.last_name || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field">
                    <label className="label">Email Address</label>
                    <input
                      className="input"
                      type="email"
                      name="email"
                      value={userInput.email || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field">
                    <label className="label">Phone Number</label>
                    <input
                      className="input"
                      type="phone"
                      name="phone"
                      value={userInput.phone || ""}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="each-field">
                    <label className="label">Company Name</label>
                    <input
                      className="input"
                      type="text"
                      name="company_name"
                      value={userInput.company_name || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                </div>

                <div className="col col--right">
                  <div className="each-field">
                    <label className="label">
                      Position in the Organisation
                    </label>
                    <input
                      className="input"
                      type="text"
                      name="position_in_the_organisation"
                      value={userInput.position_in_the_organisation || ""}
                      onChange={handleChange}
                      required
                    />
                  </div>
                  <div className="each-field each-field--dropdown">
                    <label className="label">
                      Are you a current customer with Salesforce/Tableau?
                    </label>
                    <select
                      value={userInput.current_customer_with || " "}
                      name="current_customer_with"
                      onChange={handleChange}
                      required
                    >
                      <option disabled={true} value={" "}></option>
                      <option value="Yes">Yes we are current users</option>
                      <option value="No but considering Salesforce">
                        No but we are considering Salesforce
                      </option>
                      <option value="No">None of the above</option>
                    </select>
                    <MdKeyboardArrowDown />
                  </div>
                  <div className="each-field each-field--checkboxes">
                    <label className="label">I’m interested in</label>

                    <div className="subwrapper">
                      <label htmlFor="Salesforce CRM Quickstart">
                        <input
                          type="checkbox"
                          id="Salesforce CRM Quickstart"
                          name="Tableau Quickstart"
                          value="Salesforce CRM Quickstart"
                          onChange={handleCheckbox}
                        />{" "}
                        <span>Salesforce CRM Quickstart</span>
                      </label>
                      <br />
                      <label htmlFor="Pardot Quickstart">
                        <input
                          type="checkbox"
                          id="Pardot Quickstart"
                          name="Pardot Quickstart"
                          value="Pardot Quickstart"
                          onChange={handleCheckbox}
                        />{" "}
                        <span>Pardot Quickstart</span>
                      </label>
                      <br />
                      <label htmlFor="Tableau Quickstart">
                        <input
                          type="checkbox"
                          id="Tableau Quickstart"
                          name="Tableau Quickstart"
                          value="Tableau Quickstart"
                          onChange={handleCheckbox}
                        />{" "}
                        <span>Tableau Quickstart</span>
                      </label>
                      <br />
                      <label htmlFor="Managed Services Quickstart">
                        <input
                          type="checkbox"
                          id="Managed Services Quickstart"
                          name="Managed Services Quickstart"
                          value="Managed Services Quickstart"
                          onChange={handleCheckbox}
                        />{" "}
                        <span>Managed Services Quickstart</span>
                      </label>
                    </div>
                  </div>

                  <Button className="btn">
                    {isSending ? "SENDING..." : "SUBMIT NOW"}
                  </Button>
                </div>
              </motion.form>

              <p
                className="close-btn"
                role="button"
                onClick={() => {
                  setIsPackagesForm(false);
                  setUserInput({});
                }}
              >
                <IoIosCloseCircleOutline />
              </p>
            </div>
          </ScrollLocky>
        </Wrapper>
      )}
    </AnimatePresence>
  );
};

export default PackagesForm;
